import { Box, Grid, Typography } from '@mui/material';

import c1 from './c1.png';
import c2 from './soon.png';


function ComContainer() {

  const imageContent = [
    {
      img: c1,
      text: 'ARIA COLLECTION',
      link: 'https://arialand.io'
    },

    {
      img: c2,
      text: ''
    },
    {
      img: c2,
      text: ''
    },
    {
      img: c2,
      text: ''
    }
  ]

  return (
    <Box id="Community" sx={{
      backgroundColor: "#000000",
      height: '100%',
      background: '-moz-linear-gradient(90deg, #C67F09 16.29%, #E7B833 62.79%, #F9D649 87.27%)',
      background: '-webkit-linear-gradient(90deg, #C67F09 16.29%, #E7B833 62.79%, #F9D649 87.27%)',
      background: 'linear-gradient(90deg, #C67F09 16.29%, #E7B833 62.79%, #F9D649 87.27%)',

    }} >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ padding: '60px 12vw' }}
      >
        <Grid item={'auto'}>
          <Typography sx={{
            color: '#000', fontSize: '25px', fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 700,
            letterSpacing: '0.11em',
            textTransform: 'uppercase'
          }}>
            Community driven platform
          </Typography>
        </Grid>
        <Grid item={'auto'}>
          <Typography sx={{
            color: '#000', fontSize: '18px', marginTop: '30px', fontFamily: 'Orbitron',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '27px',
            textAlign: 'center',
            width: '60vw'
          }}>
            Lead by your most favourite celebrities, artists and influencers, join one that you fit in best with and enjoy the perks of being in a community within the Meta Fushko gaming arena.
          </Typography>
        </Grid>
        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="top"
          sx={{ mt: { xs: 3, md: 5 } }}
        >
          {imageContent.map((value) =>
            <Grid xs={12} md={6} lg={3} alignItems="center" sx={{ mb: 2 }}>
              <a href='https://arialand.io' target='_blank' rel='noreferrer noopener' style={{ textDecoration: 'none' }} >
                <img src={value.img} alt='icon' />
              </a>
              <br />
              <Typography sx={{
                color: '#000', fontSize: { xs: '25px', md: '28px' }, fontFamily: 'Orbitron',
                fontStyle: 'normal',
                fontWeight: 700,
                letterSpacing: '0.11em',
                textTransform: 'uppercase'
              }}>
                {value.text}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default ComContainer;
