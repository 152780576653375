import { Box, Grid, Typography } from '@mui/material';
import market1 from './market1.png';
import market2 from './market2.png';
import market3 from './market3.png';

import { MetaBlackBtn, MetaPageHeaderDetail, MetaPageHeaderText } from './comp';

function MarketPlaceContainer() {

  return (
    <Box id="Team" sx={{
      backgroundColor: "#000000",
      paddingTop: 5
    }} >
      <Box id="MarketPlace" sx={{
        backgroundColor: "#000000",
        height: '100%',
        width: '100%',
        pt: 0,
        pb: { xs: 2, sm: 3 }
      }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: { xs: '0px 5vw', sm: '0px 7vw', lg: '0px 10vw' }, }}
        >
        </Grid>

        <Grid xs={12} md={6} >
          <Grid
            container
            direction="column"
            justifyContent="Center"
            alignItems="Center"
            sx={{ padding: { xs: '0px 5vw', sm: '0px 7vw', lg: '0px 10vw' }, }}
          >
            <Grid item={'auto'}>
              <Typography sx={{
                color: '#FFF', fontSize: { xs: '14px', sm: '17px' }, fontFamily: 'Orbitron',
                fontStyle: 'normal',
                fontWeight: 700,
                mt: { xs: '30px', sm: '50px' },
                textAlign: 'left', maxWidth: '100%'
              }}>
                THIS IS YOUR NEW IMMERSIVE ECONOMY
              </Typography>
            </Grid>
            <Grid item={'auto'}>
              <Typography sx={{
                color: '#FFF', fontSize: { xs: '14px', sm: '17px' }, fontFamily: 'Orbitron',
                fontStyle: 'normal',
                fontWeight: 300,
                mt: { xs: '15px', sm: '20px' },
                textAlign: 'left', maxWidth: '100%'
              }}>
                A game economy that is designed to reward players through leveling up their in-game assets. A
                selection of bespoke collections, each with its own flavors, identities, and communities. Every
                single in-game asset holds value according to rarity and perks, and they are all tradable with
                other players. Trade them wisely, and you’ll be able to flip them for a profit. Here are some of
                the assets:
              </Typography>
            </Grid>

          </Grid>
        </Grid>

      </Box>
      <Box id="Membership" sx={{
        backgroundColor: "#000000",
        height: '100%',
        pt: 0,
        pb: { xs: 2, sm: 3 }
      }}>
        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: { xs: '0px 5vw', sm: '0px 7vw', lg: '0px 10vw' }, }}
        >
          <Grid xs={12} md={4}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ padding: { xs: '30px 10px', sm: '60px 10px' } }}
            >
              <Grid xs={12} md={12}>
                <img src={market1} style={{ width: '100%', height: '100%', borderRadius: "25px" }} alt='banner' />
              </Grid>

              <Grid xs={12} md={12}>
                <Grid item={'auto'}>
                  <Typography sx={{
                    color: '#FFF', fontSize: { xs: '14px', sm: '17px' }, fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    mt: { xs: '30px', sm: '50px' },
                    textAlign: 'center', maxWidth: '100%',
                    textTransform: 'uppercase'

                  }}>
                    Membership pass
                  </Typography>
                </Grid>
                <Grid item={'auto'}>
                  <Typography sx={{
                    color: '#FFF', fontSize: { xs: '14px', sm: '17px' }, fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    mt: { xs: '15px', sm: '20px' },
                    textAlign: 'center', maxWidth: '100%'
                  }}>
                    This is your key to the MetaFushko community. Gain access to exclusive experiences, special
                    games, and your own community miniverse.
                  </Typography>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
          <Grid xs={12} md={4}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ padding: { xs: '30px 10px', sm: '60px 10px' } }}
            >
              <Grid xs={12} md={12}>
                <img src={market2} style={{ width: '100%', height: '100%', borderRadius: "25px" }} alt='SIGNATURE Traits' />
              </Grid>

              <Grid xs={12} md={12}>
                <Grid item={'auto'}>
                  <Typography sx={{
                    color: '#FFF', fontSize: { xs: '14px', sm: '17px' }, fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    mt: { xs: '30px', sm: '50px' },
                    textAlign: 'center', maxWidth: '100%',
                    textTransform: 'uppercase'

                  }}>
                    SIGNATURE Traits
                  </Typography>
                </Grid>
                <Grid item={'auto'}>
                  <Typography sx={{
                    color: '#FFF', fontSize: { xs: '14px', sm: '17px' }, fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    mt: { xs: '15px', sm: '20px' },
                    textAlign: 'center', maxWidth: '100%'
                  }}>
                    Owning these traits defines your character’s appearance. Acquire or earn them to gain access
                    to exclusive games and additional benefits associated with that trait class.
                  </Typography>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
          <Grid xs={12} md={4}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ padding: { xs: '30px 10px', sm: '60px 10px' } }}
            >
              <Grid xs={12} md={12}>
                <img src={market3} style={{ width: '100%', height: '100%', borderRadius: "25px" }} alt='POTENT UPGRADES' />
              </Grid>

              <Grid xs={12} md={12}>
                <Grid item={'auto'}>
                  <Typography sx={{
                    color: '#FFF', fontSize: { xs: '14px', sm: '17px' }, fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    mt: { xs: '30px', sm: '50px' },
                    textAlign: 'center', maxWidth: '100%',
                    textTransform: 'uppercase'

                  }}>
                    POWERFUL UPGRADES
                  </Typography>
                </Grid>
                <Grid item={'auto'}>
                  <Typography sx={{
                    color: '#FFF', fontSize: { xs: '14px', sm: '17px' }, fontFamily: 'Orbitron',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    mt: { xs: '15px', sm: '20px' },
                    textAlign: 'center', maxWidth: '100%'
                  }}>
                    Gain a significant advantage over other users by earning or acquiring these powerful upgrades.
                    Use them strategically, and you’ll unlock substantial rewards.
                  </Typography>
                </Grid>
              </Grid>

            </Grid>
          </Grid>

        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: '45px 8vw', background: 'linear-gradient(270deg, #F9D649 -30.87%, #915700 100%)' }}
          rowGap={4}
        >
          <MetaPageHeaderText text='START EARNING' color='#000000' weight={900} />
          <MetaPageHeaderDetail text='The foundation of the MetaFushko Marketplace and Metaverse: FSHK tokens. Users can utilize
FSHK tokens to compete against opponents on our trusted platform and purchase some of the
rarest digital collectibles.' color='#000000' />
          <MetaBlackBtn text='BUY FSHK TOKEN' />
        </Grid>

      </Box>
    </Box>
  );
}

export default MarketPlaceContainer;
